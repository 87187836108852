body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background: rgb(209 209 209 / 30%);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
img {
  width: 100%;
  display: block;
  height: auto;
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
a {
  display: block;
}
.bg-image {
  position: relative;
  width: 100%;
  background-position: center top;
  background-size: cover;
}
.spacing {
  padding-top: 88px;
}
